<template>
    <div class="parent_view">
		<div>
			<h2>第一步：导出模版</h2>
			<p class="line_30">导出模板，补充完整的终端信息</p>
			<export-table  url="/manage/crm.materiel/exportBindingTemplate">
			</export-table>
			<!-- <a-button  type="default" class="mr12 " @click='exportTemplate' >下载模版</a-button> -->
		</div>
		<div class="mar_30" >
			<h2>第二步：导入文件</h2>
			<p class="line_30">导入填写好的信息</p>
				<import-upload text='预绑定' url="/manage/crm.materiel/importBindingInfo?status=2">
				</import-upload>
				<span style='margin-left:10px;'></span>
				<import-upload  text='绑定' url="/manage/crm.materiel/importBindingInfo?status=1">
				</import-upload>
		</div>
    </div>
</template>
<script>
	import {
		exportBindingTemplate
	} from "@/api/goods";
    import {
      batchAddRole
    } from '@/api/organization.js'
	
	// 
    import {
        upload
    } from '@/api/public'
    import {
        message
    } from 'ant-design-vue';
    import moment, {
        Moment
    } from 'moment';
    export default {
        props: ['type'],
        components: {},
        data() {
            return {
				visibleTips:false,
                percent: 90,
                visibleWIFI: false,
				is_error_key:''
            }
        },
        created() {
		},
        methods: {
			

        },
        computed: {

        },

    }
</script>

<style lang="less" scoped="scoped">
    .mac_input {
        width: 60px;

        display: inline-block;
    }

    .dot_line {
        width: 10px;
        display: inline-block;
        text-align: center;
    }

    .parent_view {
        background: #fff;
        padding: 12px;
        margin: 12px;
    }

    .upload {
        padding: 30px;
        height: 300px;
        width: 80%;
        margin: 0 auto;
    }

    .tips_info {
        padding: 30px;
        width: 80%;
        margin: 0 auto;
    }

    /deep/.ant-upload-list {
        display: none;
    }

  .tips_top_box {
      font-size: 14px;
      .title{
          font-size: 16px;
          font-weight: 600;
          color: #333;
          margin-bottom: 20px;
          margin-top: -20px;
      }
      p {
          line-height: 26px;
          color: #80848f;
      }
  
      margin-bottom: 30px;
  }
  .tips_ber p:before{
          position: relative;
          top: -3px;
          display: inline-block;
          width: 5px;
          height: 5px;
          margin-right: 5px;
          background: #80848f;
          border-radius: 50%;
          content: "";
  }
  .down_link{
      color:#1890ff;
  }
  .line_30{
  	line-height: 60px;
  }
  .mar_30{
	  margin-top: 30px;
  }
  .a_btn{
	  background:#1890ff;
	  color:#fff;
  }
</style>
