<template>
    <a-modal
        :title="form_data.title"
        :visible="visible"
        @ok="submit"
        :destroyOnClose="destroyOnClose"
        @cancel="handleCancel"
		:zIndex="zIndex"
        :width="width"
    >
        <TableList
            ref="table_list"
			style_mode="pop"
            :columns="columns"
			:where='where'
            :is_created_get_list="is_created_get_list"
            :get_table_list="get_table_list"
            :form_data_seo="form_data_seo"
            :slot_table_list="slot_table_list"
			:rowSelection="rowSelection"
			:submit_preprocessing="submit_preprocessing"
			:rowKey="rowKey"
        >
            <div
                v-for="item in slot_table_list"
                :key="item"
                :slot="item"
                slot-scope="data"
            >
                <slot :name="item" :text="data.text" :record="data.record" :index="data.index"></slot>
            </div>
        </TableList>
		<slot name="pop_footer"></slot>
    </a-modal>
</template>

<script>
import TableList from './TableList'
import props_pop from './props/pop'
import props_table_list from './props/table_list'
export default {
    name: 'PopTableList',
    props: {
        ...props_pop,
        ...props_table_list,
        width: {
            type: String,
            default: '900px'
		},
		where:{
			type: Object,
			default:{}
		},
		zIndex:{
			type: Number,
			default:1000
		},
		destroyOnClose:{
			type: Boolean,
			default:true
		},
		
    },
    components: {
        TableList,
    },
    beforeCreate() {

    },
    data() {
        return {

        }
    },
    methods: {
        handleCancel() {
            this.$emit('update:visible', false)
        },
        setFieldsValue(data) {
            this.$refs.table_list.setFieldsValue(data)
        },
        get_list() {
			
            return this.$refs.table_list.get_list();
        },
        set_data(key, value) {
            return this.$refs.table_list.set_data(key, value);
        },
        submit() {
			this.$emit('update:visible', false)
			this.$emit('submit',{})
        }
    },

}
</script>
<style lang="less" scoped>
</style>
