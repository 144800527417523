<template>
	<div>
		<TableList :scroll="{x:2000}" :form_data_seo.sync="form_data_seo" :columns="columns" :get_table_list="this.get_table_list"
			:slot_table_list="['operation','status','use_status','goods_logo','goods_put','is_manual','visit_status']"
			ref="list" :is_created_get_list="false" :submit_preprocessing="submit_preprocessing">
			<template slot="left_btn">
				<a-button class="float_left" v-auth="'change'" @click="add()" type="primary">添加冰柜</a-button>
			</template>
			<template slot="right_btn">
				<a @click="report()">
					<a-button class="a_btn">导出</a-button>
				</a>

				<a @click="VisibleUpload=true">
					<a-button class="a_btn">导入</a-button>
				</a>

			</template>
			<template slot="status" slot-scope="data">
				<a-tag
					:color="(item.key == 0||item.key == 2) ? config.color.warning : (item.key == -1 || item.key == -2) ? config.color.error : config.color.primary"
					v-for="item in status_list" :key="item.key" v-show="item.key == data.text">{{ item.value }}</a-tag>
			</template>
			<template slot="visit_status" slot-scope="data">
				<div class="use_status">{{data.record.visit_status ? '是' : '否'}}</div>
			</template>
			<template slot="use_status" slot-scope="data">
				<div class="use_status">
					<div :class="'dot ' + (!data.record.use_status && 'red')"></div>
					{{data.record.use_status ? '启用' : '禁用'}}
				</div>
			</template>
			<template slot="operation" slot-scope="data">
				<a v-auth="'change'" @click="get_Binding_Info(data.record)">编辑</a>
				<a-divider v-auth="'change'" type="vertical" />
				<a v-auth="'change'" @click="edit(data.record)">操作</a>
				<a-divider v-auth="'change'" type="vertical" />
				<a @click="look_details(data.record)">查看轨迹</a>
				<a-divider v-auth="'enable'" type="vertical" />
				<!-- <a v-auth="'enable'" @click="change_status(data.record)">{{data.record.use_status ? '禁用' : '启用'}}</a>
				<a-divider v-auth="'delete'" type="vertical" /> -->
				<a-popconfirm title="您确定要删除吗?" v-auth="'delete'"
					@confirm="delBing(data.record)"><a>删除</a></a-popconfirm>
			</template>
		</TableList>
		<EditPop ref='EditPop' :title="edit_type?'编辑冰柜':'添加冰柜'" @get_select_value_tr='get_select_value_tr' @handleSubmit="add_submit"
			:form_data="form_data" :visible.sync="add_visible" >
			<template slot="add_user">
				<span v-if='edit_type'  >{{add_select_customer.name }}</span>
				<a v-auth="'change'" v-else  @click="add_customer">{{add_select_customer.name || '添加客户'}}</a>
			</template>
			<template slot="is_send_img">
				<a-switch checked-children="是" @change='handleCheckChange' :checked='send_img' un-checked-children="否" />
			</template>
			<template slot="add_code">
				<div class='code_box'>
					<div>
						<a-select placeholder="请选择" v-model='goods_code'>
							<a-select-option :value="it.value" v-for='it in goods_code_list' :key='it.value'>
								{{it.label}}
							</a-select-option>
						</a-select>
					</div>
					<div style='margin-left:12px;'>
						<a-input placeholder="请输入" v-model='goods_code_num' />
					</div>
				</div>
			</template>

			<template slot="nameplate_image">
				<div class='code_box'>
					<a-upload @change="handleChangeImg($event)" :customRequest="upload" list-type="picture-card"
						:file-list="nameplate_image" @preview="handlePreview">
						<div>
							<a-icon type="plus" />
							<div class="ant-upload-text">
								上传
							</div>
						</div>
					</a-upload>
				</div>
			</template>
		</EditPop>
		<a-modal title="操作" :visible="visible" @ok="submit" @cancel="visible = false">
			<a-radio-group class="radio_group" @change="radioChange" v-model:value="edit_type">
				<a-radio :value="-1" v-auth="'scrap'">报废</a-radio>
				<a-radio :value="1">转绑</a-radio>
				<a-radio :value="-2">遗失</a-radio>
			</a-radio-group>
			<div class="input_item" v-show="edit_type === -1 || edit_type === -2">
				<div class="text must_dot">{{edit_type === -1? '报废' : '遗失'}}原因：</div>
				<a-textarea v-model:value="scrapReason" :placeholder="edit_type === -1? '请输入报废原因' : '请输入遗失原因'" />
			</div>
			<div v-show="edit_type === 1">
				<div class="input_item">
					<div class="text must_dot">选择客户：</div>
					<a v-auth="'change'" @click="selectCustomer">{{select_customer.name || '点击添加客户'}}</a>
				</div>
				<div class="input_item">
					<div class="text must_dot">转绑原因：</div>
					<a-textarea v-model:value="bindTransferReason" placeholder="请输入转绑原因" />
				</div>
			</div>
			<div class="tips" v-if="!editing_freezer.use_status">该冰柜状态为禁用，请确认是否先启用再进行后续操作</div>
		</a-modal>

		<a-modal :footer='null' title='导入冰柜信息' v-model:visible="VisibleUpload" @ok="VisibleUpload=false">
			<uploadFreezer @success='success'></uploadFreezer>
		</a-modal>

		<SelectCustomer @submit="select_customer_add" :visible.sync="add_pid_visible"></SelectCustomer>
		<SelectCustomer @submit="select_customer_submit" :visible.sync="pid_visible"></SelectCustomer>
		<RightDrawer :visible.sync="visible_drawer">
			<template slot="content">
				<div v-for="(item, index) in locus_list" :key="index">
					<div class="drawer_title" style>
						<span v-if="item.type == 1">冰柜绑定</span>
						<span v-if="item.type == 2">冰柜解绑</span>
						<span v-if="item.type == 3">冰柜转绑</span>
						<span v-if="item.type == 4">冰柜报废</span>
						<span v-if="item.type == -2">冰柜遗失</span>
					</div>
					<a-descriptions>
						<template v-if="(item.type == 1) || (item.type == 3)">
							<a-descriptions-item :span="3" label="投放客户">{{item.customer_name}}</a-descriptions-item>
							<a-descriptions-item :span="3" label="客户地址">{{item.address}}</a-descriptions-item>
							<a-descriptions-item :span="3"
								label="联系方式">{{item.contacts_phone || '--'}}</a-descriptions-item>
							<a-descriptions-item :span="3"
								label="投放开始时间">{{ item.start_time || '--'}}</a-descriptions-item>
							<a-descriptions-item :span="3"
								label="投放结束时间">{{ item.end_time || '--'}}</a-descriptions-item>
						</template>
						<template>
							<a-descriptions-item v-if="item.type == 2" :span="3"
								label="解绑原因">{{ item.reason }}</a-descriptions-item>
							<a-descriptions-item v-if="item.type == 3" :span="3"
								label="转绑原因">{{ item.reason }}</a-descriptions-item>
							<a-descriptions-item v-if="item.type == 4" :span="3"
								label="报废原因">{{ item.reason }}</a-descriptions-item>
							<a-descriptions-item v-if="item.type == -2" :span="3"
								label="遗失原因">{{ item.reason }}</a-descriptions-item>
						</template>
						<a-descriptions-item :span="3" label="添加时间">{{add_time}}</a-descriptions-item>
						<a-descriptions-item :span="3" label="操作时间">{{item.create_time}}</a-descriptions-item>
						<a-descriptions-item :span="3" label="人员">{{item.username}}</a-descriptions-item>
					</a-descriptions>
				</div>
			</template>
		</RightDrawer>
	</div>
</template>

<script>
	// @ is an alias to /src
	import EditPop from "@/components/EditPop";
	import Form from "@/components/Form";
	import TableList from "@/components/TableList";
	import RightDrawer from "@/components/RightDrawer";

	import {
		freezerList,
		changeCompete,
		freezerLocus,
		changeFreezer,
		scrapBinding,
		bindingLost,
		bindingTransfer,
		changeStatus,
		delGoodsBinding,
		getBrandSelect,
		getBindingInfo,
		saveBindingInfo,
		exportBindingTemplate,
		getFreezerModel
	} from "@/api/goods";
	import SelectCustomer from "@/components/SelectCustomer";
	import uploadFreezer from "./uploadFreezer.vue";

	import {
		message
	} from 'ant-design-vue'

	let columns = [{
			title: "品项名称",
			dataIndex: "goods_title",

		},
		{
			title: "型号",
			dataIndex: "goods_model",
		},
		{
			title: "资产编号",
			dataIndex: "goods_code",
		},
		{
			title: "生产日期",
			dataIndex: "make_time",

		},
		{
			title: "投放客户",
			dataIndex: "customer_name",
		},

		{
			title: "客户地址",
			dataIndex: "address",
			scopedSlots: {
				customRender: "address"
			}
		},
		{
			title: "联系电话",
			dataIndex: "contact",
			width:200,
		},
		{
			title: "业务员",
			dataIndex: "username",
		},
		{
			title: "冰柜状态",
			dataIndex: "status",
			scopedSlots: {
				customRender: "status"
			}
		},
		{
			title: "当月是否拜访",
			dataIndex: "visit_status",
			scopedSlots: {
				customRender: "visit_status"
			}
		},
		{
			title: "启用状态",
			dataIndex: "use_status",
			scopedSlots: {
				customRender: "use_status"
			}
		},
		{
			title: "操作",
			scopedSlots: {
				customRender: "operation"
			},
			fixed: 'right' 
		},
	];

	let status_list = [{
			key: 1,
			value: "已绑定"
		},
		{
			key: 2,
			value: "预绑定"
		},
		{
			key: 0,
			value: "未绑定"
		},
		{
			key: -1,
			value: "报废"
		},
		{
			key: -2,
			value: "遗失"
		}
	];

	export default {
		name: "Index",
		components: {
			EditPop,
			Form,
			TableList,
			RightDrawer,
			SelectCustomer,
			uploadFreezer
		},
		data() {
			return {
				nameplate_image: [],
				goods_code: '',
				goods_code_num: '',
				code_num: '',
				VisibleUpload: false,
				add_select_customer: {},
				add_pid_visible: false,
				editing_freezer: {}, // 编辑时的冰柜
				select_customer: {}, //转绑时选择的客户信息
				pid_visible: false, // 用户选择弹窗是否显示
				scrapReason: '', // 报废原因
				bindTransferReason: '', // 转绑原因
				edit_type: 1,
				get_table_list: freezerList,
				submit_preprocessing: {
					array_to_string: ['department', 'customer_type']
				},
				config: this.$config,
				form: this.$form.createForm(this, {
					name: "advanced_search"
				}),
				columns,
				status_list,
				locus_list: [],
				details: {},
				visible: false,
				add_visible: false,
				visible_drawer: false,
				add_time:'',
				form_data_seo: {
					list: [{
							type: "tree-select",
							name: "department",
							title: "部门",
							options: {},
							treeData: [],
							multiple: true
						},
						{
							type: "tree-select",
							name: "customer_type",
							title: "客户类型",
							options: {},
							treeData: [],
							multiple: true
						},
						{
							type: "text",
							name: "keyword",
							title: "关键词",
							placeholder: '品项名称/业务员/手机号/资产编号/资产型号',
							options: {

							}
						},
						{
							type: 'select',
							title: '状态',
							name: 'status',
							options: {},
							list: status_list
						},
						{
							type: 'select',
							title: '录入方式',
							name: 'is_manual',
							options: {},
							list: [{
									key: '',
									value: "全部"
								},
								{
									key: 0,
									value: "扫码录入"
								},
								{
									key: 1,
									value: "手动录入"
								},
							]
						},
						{
							type: "range_date",
							name: "range_date",
							title: "创建时间",
							options: {},
							start: {
								name: 'start_time'
							},
							end: {
								name: 'end_time'
							},
						},
						{
							type: 'select',
							title: '当月是否已拜访',
							name: 'visit_status',
							options: {},
							list: [{
									key: 1,
									value: "是"
								},
								{
									key: 0,
									value: "否"
								},
							]
						},
						{
							type: 'select',
							title: '启用状态',
							name: 'use_status',
							options: {},
							list: [{
									key: 1,
									value: '启用'
								},
								{
									key: 0,
									value: '禁用'
								},
							]
						},

					],
					...this.$config.form_data_seo
				},
				
				form_data: {
					title: '添加冰柜',
					list: [{
							type: 'text',
							name: 'id',
							hidden: true,
							options: {
								initialValue:''
							},
						},
						{
							type: 'slot',
							name: 'add_user',
							title: '选择客户',
							options: {},
							disabled: true,
						},
						{
							type: 'text',
							name: 'user_name',
							title: '业务员',
							options: {},
							hidden: true,
							disabled: true,
						},
						{
							type: 'text',
							name: 'customer_name',
							title: '投放客户',
							options: {},
							hidden: true,
							disabled: true,
						},
						{
							type: 'text',
							name: 'contacts_name',
							title: '联系方式',
							hidden: true,
							disabled: true,
							options: {

							},
						},
						{
							type: 'text',
							name: 'address',
							title: '客户地址',
							hidden: true,
							disabled: true,
							options: {

							},
						},
						{
							type: 'slot',
							name: 'is_send_img',
							title: '铭牌丢/损失',
							options: {
								initialValue: false
							},
						},
						
						{
							type: 'slot',
							name: 'nameplate_image',
							title: '上传图片',
							hidden: true,
							options: {
								rules: [{
									required: true,
								}]
							},
						},
						{
							type: 'text',
							name: 'goods_title',
							title: '品项名称',
							hidden: true,
							options: {
								
							},
						},
						{
						    type: 'radio',
						    name: 'status',
						    title: '状态',
						    list: [
						        {
						            key: 1,
						            value: "绑定"
						        },
						        {
						            key: 2,
						            value: "预绑定"
						        }
						    ],
						    options: {
						    	
						    },
						},
						{
							type: 'text',
							name: 'outcome_id',
							title: '出库单ID',
							hidden: false,
							options: {
						
							},
						},
						{
							type: 'select',
							name: 'goods_model',
							title: '产品型号',
							hidden: false,
							list: [],
							options: {
								rules: [{
									required: false,
								}]
							},
						},
						{
							type: 'slot',
							name: 'add_code',
							title: '资产编号',
							hidden: false,
							list: [],
							options: {
								rules: [{
									required: false,
								}]
							},
						},
						{
							type: 'date',
							name: 'make_time',
							hidden: false,
							title: '生产日期',
							options: {
								rules: [{
									required: false,
								}]
							},
						},
						{
							type: 'text',
							name: 'service_mobile',
							hidden: false,
							title: '售后电话',
							options: {

							},
						},
						{
							type: 'text',
							name: 'reason',
							title: '备注',
							options: {

							},
						},
					]
				},
				bindingTransfer,
				scrapBinding,
				bindingLost,
				goods_model: '',
				send_img:false,
				edit_type:false,
				goods_code_list: []
			};
		},
		async created() {
			let query = this.$route.query;
			this.$method.get_department().then(res => {
				this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'department',
					'treeData', res)
			});
			this.$method.get_group().then(res => {
				this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'customer_type',
					'treeData', res)
			});
			this.$nextTick(() => {
				// 启用状态默认只筛选启用的
				this.$method.setSeoFormValue(this, {
					...query,
					use_status: 1,
				})
				this.$refs.list.get_list();
			})
			this.get_Freezer_Model()
		},
		methods: {
			async handlePreview(file) {
				this.previewImage = file.url || file.response.url;
				this.previewVisible = true;
			},
			handleChangeImg(e) {
				this.nameplate_image = e ? e.fileList : [];
			},
			upload(e, item) {
				return new Promise((resolve, reject) => {
					this.$req({
						mode: 'file',
						url: '/manage/api.plugs/upload',
						data: {
							file: e.file,
						}
					}).then(res => {
						e.onSuccess(res.data.upload_data);
						resolve(res);
					}).catch(e => {
						e.onError();
						reject(e)
					})
				})
			},
			get_Binding_Info(data) {
				this.edit_type = true
				this.form_data.title = '编辑冰柜'
				this.nameplate_image = []
				getBindingInfo({
					data: {
						id: data.id
					}
				}).then(res => {
					this.form_data.list.forEach(item => {
						item.options.initialValue = res.data.list[item.name]
						this.$forceUpdate()
					})
					
					this.form_data.list.forEach(item => {
						if (item.name == 'address') {
							item.options.initialValue = res.data.list.address
						}
						if (item.name == 'id') {
							item.options.initialValue = data.id
						}
						
						if (item.name == 'user_name') {
							item.options.initialValue = res.data.list.username
						}
						if (item.name == 'customer_name') {
							item.options.initialValue = res.data.list.customer_name
						}
						
						if (item.name == 'outcome_id') {
							item.options.initialValue = res.data.list.outcome_id
						}
						
						if (item.name == 'status') {
							item.options.initialValue =  res.data.list.status
						}
						if (item.name == 'goods_title') {
							item.options.initialValue =  res.data.list.goods_title
						}
						if (item.name == 'make_time') {
							item.options.initialValue = res.data.list.make_time?this.$moment(
                                            res.data.list.make_time,
                                            "YYYY/MM/DD"
                                        ):''; 
						}
						
						
					})
					
					this.send_img = res.data.list.is_send_img ? true : false
					this.get_Brand_Select(res.data.list.goods_model)
					this.goods_code = res.data.list.goods_prefix
					this.goods_code_num = res.data.list.goods_suffix
					this.add_select_customer = {
						name: res.data.list.customer_name,
						id: res.data.list.customer_id
					}
					this.add_visible = true;
					if (res.data.list.is_send_img) {
						// 丢失
						this.set_blo(true)
					} else {
						// 未丢失
						this.set_blo(false)
					}
					res.data.list.nameplate_image.forEach((item, index) => {
						let obj = {
							uid: index,
							name: index,
							status: index,
							url: item ? item : ''
						}
						this.nameplate_image.push(obj)
					})
				})
			},
			add_submit(e) {
				let nameplate_image = []
				if (this.send_img) {
					if (this.nameplate_image.length == 0) {
						message.error('请上传冰柜图片');
						return false;
					}
					this.nameplate_image.forEach(item => {
						nameplate_image.push(item.response ? item.response.url : item.url)
					})
				}
				
				saveBindingInfo({
					data: {
						outcome_id:e.values.outcome_id,
						"customer_id": this.add_select_customer.id, //客户id
						"goods_model": e.values.goods_model, //产品型号
						"goods_code": this.goods_code + this.goods_code_num, //资产编号
						"goods_title": e.goods_title, //品项名称
						"make_time": e.values.make_time, //生成日期
						"service_mobile": e.values.service_mobile, //售后电话
						"reason": e.values.reason, //备注
						id:e.values.id?e.values.id:'',
						status: e.values.status,
						"is_send_img": this.send_img ? 1 : 0, //是否损坏铭牌 1是0否
						"nameplate_image": this.send_img?nameplate_image.join(','):'' //图片
					},
					info: true
				}).then(res => {
					this.goods_code = '';
					this.goods_code_num = '';
					this.nameplate_image = []
					this.add_visible = false;
					this.$refs.list.get_list();
					this.clear_info()
				})
			},
			success() {
				this.$refs.list.get_list();
			},
			get_select_value_tr(e) {
				if (e.label == "goods_model") {
					this.get_Brand_Select(e.value)
				}
			},
			get_Freezer_Model(data) {
				getFreezerModel({
					data: {
						goods_model: data
					}
				}).then(res => {
					this.form_data.list.forEach(item => {
						if (item.name == 'goods_model') {
							item.list = res.data.list
						}
					})
				})
			},
			get_Brand_Select(value) {
				getBrandSelect({
					data: {
						goods_model: value
					}
				}).then(res => {
					this.goods_code_list = res.data.list
				})
			},
			set_blo(flag) {
				this.form_data.list.forEach(item => {
					if (item.name == 'goods_model') {
						item.hidden = flag
					}
					if (item.name == 'goods_title') {
						item.hidden = flag
					}
					if (item.name == 'add_code') {
						item.hidden = flag
					}
					if (item.name == 'outcome_id') {
						item.hidden = flag
					}
					if (item.name == 'make_time') {
						item.hidden = flag
					}
					if (item.name == 'service_mobile') {
						item.hidden = flag
					}
					if (item.name == 'status') {
						item.hidden = flag
					}
					if (item.name == 'nameplate_image') {
						item.hidden = !flag
					}
					if (item.name == 'goods_title') {
						item.hidden = flag
					}
					this.$forceUpdate()
				})
			},
			handleCheckChange(e) {
				console.log('e',e)
				this.send_img = e
					if (e) {
						
						// 丢失
						this.set_blo(true)
					} else {
						// 未丢失
						this.set_blo(false)
					}
			},
			select_customer_add({
				data
			}) {
				this.add_select_customer = data
				this.form_data.list.forEach(item => {
					if (item.name == 'address') {
						item.hidden = false
						item.options.initialValue = data.address
					}
					if (item.name == 'user_name') {
						item.hidden = false
						item.options.initialValue = data.username
					}
					if (item.name == 'customer_name') {
						item.hidden = false
						item.options.initialValue = data.name
					}
				})
			},
			add_customer() {
				this.add_pid_visible = true
			},
			add() {
				this.edit_type = false
				this.add_visible = true
				this.clear_info()
				this.form_data.title = '添加冰柜'
			},
			clear_info(){
				this.add_select_customer ={}
				this.form_data.list.forEach(item => {
					item.options.initialValue = ''
					this.$forceUpdate()
				})
				this.goods_code = '';
				this.goods_code_num = '';
				this.form_data.list.forEach(item => {
					if (item.name == 'address') {
						item.hidden = true
					}
					if (item.name == 'user_name') {
						item.hidden = true
					}
					if (item.name == 'customer_name') {
						item.hidden = true
					}
				})
			},
			change_status(record) {
				let use_status = record.use_status ? 0 : 1;
				changeStatus({
					data: {
						id: record.id,
						status: use_status
					},
					loading: true,
					info: true
				}).then(res => {
					record.use_status = use_status;
				})
			},
			select_customer_submit({
				data
			}) {
				this.pid_visible = false;
				this.select_customer = data;
			},
			selectCustomer() {
				this.pid_visible = true;
			},
			radioChange(e) {
				this.scrapReason = ''
				this.bindTransferReason = ''
			},
			look_details(record) {
				this.visible_drawer = true;
				freezerLocus({
					data: {
						binding_id: record.id
					}
				}).then(res => {
					this.locus_list = res.data.list;
					this.add_time = res.data.add_time
				})
			},
			edit(record) {
				this.scrapReason = '';
				this.select_customer = {};
				this.bindTransferReason = '';
				this.editing_freezer = record;
				this.visible = true;
			},
			delBing(record) {
				delGoodsBinding({
					data: {
						id: record.id
					},
					info: true
				}).then(res => {
					this.$refs.list.get_list();
				})
			},

			submit(e) {
				if (this.edit_type === 1) {
					if (!this.select_customer.id) {
						message.error('请选择客户');
						return
					}
					if (!this.bindTransferReason) {
						message.error('请输入转绑原因');
						return
					}
				} else {
					if (!this.scrapReason) {
						let text = this.edit_type === -1 ? '报废' : '遗失'
						message.error('请输入' + text + '原因');
						return
					}
				}

				let data = {
					id: this.editing_freezer.id,
					customer_id: this.select_customer.id,
					reason: this.edit_type === 1 ? this.bindTransferReason : this.scrapReason
				}
				this[this.edit_type === 1 ? 'bindingTransfer' : this.edit_type === -1 ? 'scrapBinding' : 'bindingLost']({
					data: {
						...data
					},
					info: true
				}).then(res => {
					this.$refs.list.get_list();
					this.visible = false;
				})

			},
			report() {
				this.$router.push({
					path: "/report/modify_report",
					query: {
						report_key: "custom_freezer_list",
						...this.$refs.list.seo_data
					}
				});
			},

		}
	};
</script>

<style lang="less">
	@import url("../../assets/less/app.less");

	.drawer_title {
		padding: 15px 0;
	}

	.use_status {
		display: flex;
		align-items: center;

		.dot {
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background: @success-color;
			margin-right: 8px;
		}

		.red {
			background: @error-color;
		}
	}

	.input_item {
		display: flex;
		align-items: center;
		margin-top: 14px;

		.text {
			white-space: nowrap;
			font-size: 14px;
			margin-right: 4px;
		}
	}

	.tips {
		margin-top: 8px;
		color: @error-color;
		font-size: 14px;
	}

	.code_box {
		display: flex;
		align-items: center;

		div {
			flex: 1;
		}
	}
</style>