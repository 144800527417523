export default {
	visible: {
		type: Boolean,
		default: false,
	},
	width: {
		type: [String, Number],
		default: 520,
	},
	destroyOnClose: {
		type: Boolean,
		default: true,
	},
	form_data: {
		type: Object,
		default: () => {
			return {
				list: []
			}
		}
	},
}